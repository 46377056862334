import {
  IGetHistoryReports,
  IGetReportById,
} from "./history-reports.interface";
import {
  HistoryReportsTypes,
  IGetHistoryReportsRequestAction,
  IGetReportByIDRequestAction,
} from "./history-reports.type";

export const getHistoryReports = (
  payload?: IGetHistoryReports
): IGetHistoryReportsRequestAction => ({
  type: HistoryReportsTypes.GET_HISTORY_REPORTS,
  payload,
});
export const getReportById = (
  payload: IGetReportById
): IGetReportByIDRequestAction => ({
  type: HistoryReportsTypes.GET_REPORT_BY_ID,
  payload,
});
