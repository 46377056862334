type VariantTypes = "p" | "h1" | "h2" | "h3";

type TypographyProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
> & {
  variant?: VariantTypes;
  children?: React.ReactNode;
  bold?: boolean;
};

export const Typography: React.FC<TypographyProps> = ({
  variant = "p",
  children,
  className,
  bold = false,
  ...props
}) => {
  return (
    <p
      className={`${
        variant === "h1"
          ? "text-2xl"
          : variant === "h2"
          ? "text-xl"
          : variant === "h3"
          ? "text-lg"
          : "text-md"
      } text-gray-900 ${
        bold ? "font-semibold" : ""
      } dark:text-gray-300 ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};
