import { Suspense, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { AlertMessage } from "./components/AlertMessage";
import "react-toastify/dist/ReactToastify.css";
import { RenderProtectedRoutes } from "./routes";
import { useDispatch } from "react-redux";
import { KEY_INFO_USER } from "./constants/app-const";
import { handleActionSucceeded } from "./stores/app";
import { ProfileTypes } from "./stores/profile";

function App() {
  const dispatch = useDispatch();
  const protectedElements = useRoutes(RenderProtectedRoutes());
  // const publicElements = useRoutes(publicRoutes);

  useEffect(() => {
    const infoUser: any = localStorage.getItem(KEY_INFO_USER);
    if (infoUser) {
      let inforParsed = JSON.parse(infoUser);
      if (inforParsed?.user) {
        const profile = JSON.parse(inforParsed?.user);
        dispatch(handleActionSucceeded(ProfileTypes.GET_PROFILE, profile));
      }
    }
  }, []);

  return (
    <div className="w-full h-full flex">
      <AlertMessage />
      <Suspense
        fallback={
          <span
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
            }}
            className="bg-white dark:bg-slate-800"
          />
        }
      >
        {protectedElements}
      </Suspense>
    </div>
  );
}

export default App;
