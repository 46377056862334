import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

export type SingleLinkProps = {
  icon?: React.ReactNode;
  name: string;
  to: string;
  canAccess?: boolean;
};

export const SingleLink: React.FC<SingleLinkProps> = ({
  icon,
  name,
  to,
  canAccess = false,
}) => {
  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    if (pathname === to) return true;
    return false;
  }, [pathname, to]);

  if (!canAccess) return null;

  return (
    <li className="relative mb-2 px-4">
      {isActive && (
        <span className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg" />
      )}
      <Link
        className={`inline-flex items-center ${
          isActive ? "bg-primary" : ""
        }  px-2 py-2 dark:hover:bg-slate-700 hover:bg-gray-100 w-full ${
          isActive ? "text-white" : ""
        } text-sm font-semibold transition-colors duration-150 hover:text-primary dark:hover:text-gray-200 rounded-md`}
        to={to}
      >
        {!!icon && icon}
        <span className="ml-4">{name}</span>
      </Link>
    </li>
  );
};
