import { FC } from "react";
import { IconCustomProps } from "..";

export const PlusIcon: FC<IconCustomProps> = ({
  width = 24,
  height = 24,
  color = "#000",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={color}
    width={width}
    height={height}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>
);
