import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { IAppState } from "../../stores/state";
import { hideMessage, MessageTypes } from "../../stores/app";

const TIME_SHOW = 3000;

export const AlertMessage = () => {
  const dispatch = useDispatch();
  const { isOpen, message, severity } = useSelector(
    (state: IAppState) => state.appMessage
  );

  const renderToast = (message: string, severity: MessageTypes = "success") => {
    switch (severity) {
      case "success": {
        toast.success(message);
        break;
      }
      case "error": {
        toast.error(message);
        break;
      }
      default: {
        toast.success(message);
        break;
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      renderToast(message, severity);
      dispatch(hideMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, message, severity]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={TIME_SHOW}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};
