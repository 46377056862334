import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import {
  ISendEmailRequestAction,
  ISendReportRequestAction,
  SendReportTypes,
} from "./send-report.type";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
} from "../app";
import { AxiosResponse } from "axios";
import { Requests } from "../../api/requests";
import { IResponseSendReport } from "./send-report.interface";
import { BASE_URL, ENDPOINTS } from "../../api/endpoint";
import { objectToFormData } from "../../helper";

function* sendReport({ payload, type }: ISendReportRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.create_report;
    const res: AxiosResponse<IResponseSendReport> = yield Requests.post(
      apiUrl,
      objectToFormData(payload)
    );
    if (res.data) {
      yield put(handleActionSucceeded<IResponseSendReport>(type, res.data));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.message));
  }
}
function* watchSendReport() {
  yield takeLatest(SendReportTypes.SEND_REPORT, sendReport);
}

function* sendEmail({ payload, type }: ISendEmailRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.send_email;
    const res: AxiosResponse<any> = yield Requests.post(
      apiUrl,
      payload,
      true,
      true
    );
    if (res.data) {
      yield put(handleActionSucceeded<any>(type, res.data));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.message));
  }
}
function* watchSendEmail() {
  yield takeLatest(SendReportTypes.SEND_EMAIL, sendEmail);
}

function* sendReportSaga() {
  yield all([fork(watchSendReport), fork(watchSendEmail)]);
}
export default sendReportSaga;
