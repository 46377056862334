import {
  IChangePassword,
  ICreateUser,
  IDeleteUser,
} from "./manage-user.interface";

export enum ManageUserTypes {
  CREATE_USER = "CREATE_USER",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  DELETE_USER = "DELETE_USER",
}

export interface ICreateUserRequestAction {
  type: typeof ManageUserTypes.CREATE_USER;
  payload: ICreateUser;
}

export interface IChangePasswordRequestAction {
  type: typeof ManageUserTypes.CHANGE_PASSWORD;
  payload: IChangePassword;
}

export interface IDeleteUserRequestAction {
  type: typeof ManageUserTypes.DELETE_USER;
  payload: IDeleteUser;
}

export type ManageUserActions =
  | ICreateUserRequestAction
  | IChangePasswordRequestAction
  | IDeleteUserRequestAction;
