import { Reducer } from "redux";
import { GlobalReducer } from "../app";
import { initialResponseState, IResponseState } from "../state";
import {
  ILoginClientRequestAction,
  LoginClientActions,
  LoginClientTypes,
} from "./login-client.type";

export const LoginClientReducer: Reducer<IResponseState, LoginClientActions> = (
  state = initialResponseState,
  action: ILoginClientRequestAction
) => GlobalReducer(state, action, LoginClientTypes.LOGIN_CLIENT.toString());
