import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import { Requests } from "../../api/requests";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
  showMessage,
} from "../app";
import { BASE_URL, ENDPOINTS } from "../../api/endpoint";
import {
  ICreateLocationRequestAction,
  IDeleteLocationRequestAction,
  IGetLocationRequestAction,
  LocationTypes,
} from "./location.type";
import { AxiosResponse } from "axios";
import { IResponseLocations } from "./location.interface";
import { objectToFormData } from "../../helper";

function* getLocation({ payload, type }: IGetLocationRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.get_locations;
    const res: AxiosResponse<IResponseLocations> = yield Requests.get(apiUrl, {
      ...payload,
    }, false);
    if (res.data) {
      yield put(handleActionSucceeded<IResponseLocations>(type, res.data));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchGetLocation() {
  yield takeLatest(LocationTypes.GET_LOCATION, getLocation);
}

function* createLocation({ payload, type }: ICreateLocationRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.create_locations;
    const res: AxiosResponse<IResponseLocations> = yield Requests.post(
      apiUrl,
      objectToFormData(payload)
    );
    if (res.data) {
      yield put(handleActionSucceeded(type, res));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchCreateLocation() {
  yield takeLatest(LocationTypes.CREATE_LOCATION, createLocation);
}

function* deleteLocation({ id, type }: IDeleteLocationRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.delete_location;
    const formData = new FormData();
    formData.append("location_id", id);
    const res: AxiosResponse<any> = yield Requests.post(
      apiUrl,
      objectToFormData({ location_id: id }),
      true,
      true
    );
    if (res?.data?.status === "successful") {
      yield put(showMessage("Xóa khu vực thành công"));
      yield put(handleActionSucceeded(type, res));
    } else {
      yield put(handleActionFailed(type, res?.data?.detail));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchDeleteLocation() {
  yield takeLatest(LocationTypes.DELETE_LOCATION, deleteLocation);
}

export function* locationSaga() {
  yield all([
    fork(watchGetLocation),
    fork(watchCreateLocation),
    fork(watchDeleteLocation),
  ]);
}
