import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  KEY_INFO_USER,
  KEY_TOKEN,
  RolesAdmin,
} from "../../constants/app-const";
import { resetDataState } from "../../stores/app";
import { LoginTypes } from "../../stores/login";

type LogoutProps = {
  role: RolesAdmin;
  className?: string;
  title?: string;
};

export const Logout: FC<LogoutProps> = ({
  role,
  className,
  title = "Đăng xuất",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(resetDataState(LoginTypes.LOGIN));
    localStorage.setItem(KEY_INFO_USER, "");
    localStorage.setItem(KEY_TOKEN, "");
    role === "admin" && navigate("/admin/login");
    role === "agency" && navigate("/");
  };
  return (
    <div
      onClick={handleLogout}
      className={`inline-flex w-full min-w-max items-center text-gray-700 dark:text-gray-300 cursor-pointer px-2 py-2 text-sm font-semibold transition-colors duration-150 rounded-sm hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200 ${className}`}
    >
      <span>{title}</span>
      <p className="transform rotate-180">
        <svg
          className="w-5 h-5 mr-4"
          aria-hidden="true"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
        </svg>
      </p>
    </div>
  );
};
