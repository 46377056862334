import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/Button";
import { InputController } from "../../../../components/Input";
import { resetDataState } from "../../../../stores/app";
import {
  createLocation,
  getLocation,
} from "../../../../stores/location/location.action";
import { LocationTypes } from "../../../../stores/location/location.type";
import { IAppState } from "../../../../stores/state";
import { PlusIcon } from "../../../../svg-icons/PlusIcon";

type IProps = {
  location_id?: number;
  isShow?: boolean;
  onCloseModal?: () => void;
  onShow?: () => void;
  loading?: boolean;
};

const AddLocation = ({
  location_id,
  isShow = false,
  onCloseModal = () => null,
  onShow = () => null,
  loading = false,
}: IProps) => {
  const { control, handleSubmit, setValue } = useForm();
  const resultCreateLocation = useSelector(
    (state: IAppState) => state.createLocations
  );
  const dispatch = useDispatch();
  const handleSubmitCreateLocation = (value: any) => {
    if (location_id) {
      dispatch(
        createLocation({
          name_location: value.name_location,
          location_id,
        })
      );
    } else {
      dispatch(
        createLocation({
          name_location: value.name_location,
        })
      );
    }
  };

  useEffect(() => {
    if (resultCreateLocation.data) {
      setValue("name_location", "");
      dispatch(resetDataState(LocationTypes.CREATE_LOCATION));
      if (location_id) {
        dispatch(getLocation({ location_id: location_id }));
      } else {
        dispatch(getLocation());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultCreateLocation, location_id]);

  return (
    <>
      <div className="cursor-pointer" onClick={onShow}>
        <PlusIcon />
      </div>
      <Transition appear show={isShow} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white py-5 px-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-semibold leading-6 text-gray-900 mb-4"
                  >
                    Tạo khu vực mới
                  </Dialog.Title>
                  <div className="mt-4">
                    <p>Tên khu vực</p>
                    <InputController
                      control={control}
                      placeholder="Nhập vào tên khu vực"
                      name="name_location"
                    />
                  </div>

                  <div className="mt-6 flex justify-end gap-2">
                    <div>
                      <button
                        onClick={onCloseModal}
                        className="flex h-10 text-center justify-center items-center text-slate-700 shadow-lg w-full px-4 text-base font-semibold bg-slate-300 rounded-md"
                      >
                        Hủy
                      </button>
                    </div>
                    <div>
                      <Button
                        title="Tạo khu vực"
                        onClick={handleSubmit(handleSubmitCreateLocation)}
                        loading={loading}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddLocation;
