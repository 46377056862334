import { IParamsGetList } from "./list-reports.interface";

export enum ListReportTypes {
  GET_LIST_REPORT = "GET_LIST_REPORT",
}

export interface IGetListReportsAction {
  type: typeof ListReportTypes.GET_LIST_REPORT;
  param?: IParamsGetList;
}

export type ListReportActions = IGetListReportsAction;
