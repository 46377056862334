export const BASE_URL = {
  dev:
    process.env.node_env === "production"
      ? "https://api.dtts.bmte.vn/api/v1"
      : process.env.REACT_APP_API || "https://api.dtts.bmte.vn/api/v1",
  // dev: "http://103.168.58.73:8000/api/v1",
};

export const ENDPOINTS = {
  create_report: "/dm/reports/client",
  get_reports_client: "/dm/reports/client",
  authenticate: "/user/authenticate",
  get_list_reports_admin: "/dm/reports/admin",
  get_locations: "/dm/detail-location",
  create_locations: "/dm/create_location",
  manage_user: "/user/manage_users",
  info_location: "/dm/locations",
  delete_location: "/dm/delete_location",
  send_email: "/dm/send_mail",
};
