import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import { Requests } from "../../api/requests";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
} from "../app";
import { BASE_URL, ENDPOINTS } from "../../api/endpoint";
import { IGetListReportsAction, ListReportTypes } from "./list-reports.type";
import { IListReports } from "./list-reports.interface";

function* getListReport({ param, type }: IGetListReportsAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.get_list_reports_admin;
    const res: { detail: IListReports } = yield Requests.get(apiUrl, {
      ...param,
    });
    const dataRes: any = { ...res };
    if (dataRes) {
      yield put(handleActionSucceeded<IListReports>(type, dataRes));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchListReport() {
  yield takeLatest(ListReportTypes.GET_LIST_REPORT, getListReport);
}

export function* listReportSaga() {
  yield all([fork(watchListReport)]);
}
