import { IDataAutoComplete } from "../components/Autocomplete";
import { AreaTypes } from "../constants/meal-data";
import { ILocation } from "../stores/login-client";

export const convertToAutocomplete = (
  data: ILocation[],
  type: AreaTypes
): IDataAutoComplete[] => {
  return data.map((d) => ({
    label:
      type === "province"
        ? d.province
        : type === "district"
        ? d.district
        : d.ward,
    value: d.id,
  }));
};
