import { ICreateLocation, IFilterLocation } from "./location.interface";
import {
  ICreateLocationRequestAction,
  IDeleteLocationRequestAction,
  IGetLocationRequestAction,
  LocationTypes,
} from "./location.type";

export const getLocation = (
  payload?: IFilterLocation
): IGetLocationRequestAction => ({
  type: LocationTypes.GET_LOCATION,
  payload,
});
export const createLocation = (
  payload: ICreateLocation
): ICreateLocationRequestAction => ({
  type: LocationTypes.CREATE_LOCATION,
  payload,
});
export const deleteLocation = (id: string): IDeleteLocationRequestAction => ({
  type: LocationTypes.DELETE_LOCATION,
  id,
});
