import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import { Requests } from "../../api/requests";
import { AxiosResponse } from "axios";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
} from "../app";
import {
  ILoginClientRequestAction,
  LoginClientTypes,
} from "./login-client.type";
import { IResponseLoginClient } from "./login-client.interface";
import { BASE_URL, ENDPOINTS } from "../../api/endpoint";
import { IProfile, ProfileTypes } from "../profile";

function* loginClient({ payload, type }: ILoginClientRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.authenticate;
    const res: AxiosResponse<{ data: IResponseLoginClient }> =
      yield Requests.post(
        apiUrl,
        {
          ...payload,
        },
        false
      );
    if (res.data) {
      yield put(
        handleActionSucceeded<IResponseLoginClient>(type, res.data.data)
      );
      yield put(
        handleActionSucceeded<IProfile>(
          ProfileTypes.GET_PROFILE,
          res.data.data.user.userLocation
        )
      );
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.message));
  }
}
function* watchLogin() {
  yield takeLatest(LoginClientTypes.LOGIN_CLIENT, loginClient);
}

export function* rootLoginClientSaga() {
  yield all([fork(watchLogin)]);
}
