import { Reducer } from "redux";
import { GlobalReducer } from "../app";
import { initialResponseState, IResponseState } from "../state";
import { LocationActions, LocationTypes } from "./location.type";

export const GetLocationReducer: Reducer<IResponseState, LocationActions> = (
  state = initialResponseState,
  action: LocationActions
) => GlobalReducer(state, action, LocationTypes.GET_LOCATION.toString());

export const CreateLocationReducer: Reducer<IResponseState, LocationActions> = (
  state = initialResponseState,
  action: LocationActions
) => GlobalReducer(state, action, LocationTypes.CREATE_LOCATION.toString());
export const DeleteLocationReducer: Reducer<IResponseState, LocationActions> = (
  state = initialResponseState,
  action: LocationActions
) => GlobalReducer(state, action, LocationTypes.DELETE_LOCATION.toString());
