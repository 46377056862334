import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
} from "../app";
import { AxiosResponse } from "axios";
import { Requests } from "../../api/requests";
import {
  HistoryReportsTypes,
  IGetHistoryReportsRequestAction,
  IGetReportByIDRequestAction,
} from "./history-reports.type";
import { IResponseGetHistoryReports } from "./history-reports.interface";
import { BASE_URL, ENDPOINTS } from "../../api/endpoint";

function* getHistoryReports({
  payload,
  type,
}: IGetHistoryReportsRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.get_reports_client;
    const res: AxiosResponse<IResponseGetHistoryReports> = yield Requests.get(
      apiUrl,
      {
        ...payload,
      }
    );
    if (res.data) {
      yield put(
        handleActionSucceeded<IResponseGetHistoryReports>(type, res.data)
      );
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.message));
  }
}
function* watchGetHistoryReports() {
  yield takeLatest(HistoryReportsTypes.GET_HISTORY_REPORTS, getHistoryReports);
}

function* getReportById({ payload, type }: IGetReportByIDRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.get_list_reports_admin;
    const res: AxiosResponse<IResponseGetHistoryReports> = yield Requests.get(
      apiUrl,
      {
        ...payload,
      }
    );
    if (res.data) {
      yield put(
        handleActionSucceeded<IResponseGetHistoryReports>(type, res.data)
      );
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.message));
  }
}
function* watchGetReportById() {
  yield takeLatest(HistoryReportsTypes.GET_REPORT_BY_ID, getReportById);
}

function* historyReportsSaga() {
  yield all([fork(watchGetHistoryReports), fork(watchGetReportById)]);
}
export default historyReportsSaga;
