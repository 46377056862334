type DocumentIconProps = {
  width?: number;
  height?: number;
};

export const DocumentIcon: React.FC<DocumentIconProps> = ({
  width = 20,
  height = 20,
}) => (
  <svg
    width={width}
    height={height}
    aria-hidden="true"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
  </svg>
);
