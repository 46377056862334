import { ISendReport } from "./send-report.interface";

export enum SendReportTypes {
  SEND_REPORT = "SEND_REPORT",
  SEND_EMAIL = "SEND_EMAIL",
}

export interface ISendReportRequestAction {
  type: typeof SendReportTypes.SEND_REPORT;
  payload: ISendReport;
}
export interface ISendEmailRequestAction {
  type: typeof SendReportTypes.SEND_EMAIL;
  payload: FormData;
}

export type SendReportActions =
  | ISendReportRequestAction
  | ISendEmailRequestAction;
