import { IGlobalAction } from "../state";
import { MessageTypes } from "./app.inteface";
import {
  AppTypes,
  IAppMessageHideAction,
  IAppMessageShowAction,
} from "./app.type";

// Actions show/hide message app
export const showMessage = (
  message: string,
  severity: MessageTypes = "success"
): IAppMessageShowAction => ({
  type: AppTypes.APP_MESSAGE_SHOW,
  message,
  severity,
});

export const hideMessage = (): IAppMessageHideAction => ({
  type: AppTypes.APP_MESSAGE_HIDE,
});

// Initial global actions
export function resetDataState(type: string): IGlobalAction {
  return {
    type: `${type}_SUCCEEDED`,
    data: null,
  };
}

export function handleActionRequested(type: string): IGlobalAction {
  return {
    type: `${type}_REQUESTED`,
    isLoading: true,
  };
}

export function handleActionSucceeded<T>(
  type: string,
  data: T
): IGlobalAction<T> {
  return {
    type: `${type}_SUCCEEDED`,
    data,
  };
}

export function handleActionFailed(
  type: string,
  message: string
): IGlobalAction {
  return {
    type: `${type}_FAILED`,
    message,
  };
}
