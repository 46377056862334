import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetDataState } from "../../../../stores/app";
import {
  deleteLocation,
  getLocation,
} from "../../../../stores/location/location.action";
import { IAppState } from "../../../../stores/state";
import { LocationTypes } from "../../../../stores/location/location.type";

type IProps = {
  location_id: string;
  isShow?: boolean;
  onCloseModal?: () => void;
};

const DeleteLocation = ({
  location_id,
  isShow,
  onCloseModal = () => {},
}: IProps) => {
  const dispatch = useDispatch();
  const resultDelete = useSelector((state: IAppState) => state.deleteLocations);

  const onSubmitDelete = () => {
    dispatch(deleteLocation(location_id));
  };

  useEffect(() => {
    if (resultDelete?.data) {
      dispatch(resetDataState(LocationTypes.DELETE_LOCATION));
      dispatch(getLocation());
      onCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultDelete]);

  return (
    <>
      <Transition appear show={isShow} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white py-5 px-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-semibold leading-6 text-gray-900 mb-4"
                  >
                    Xác nhận xóa khu vực
                  </Dialog.Title>

                  <div className="mt-6 flex justify-center gap-2">
                    <div>
                      <button
                        onClick={onCloseModal}
                        className="flex h-10 text-center justify-center items-center text-slate-700 shadow-lg w-full px-4 text-base font-semibold bg-slate-300 rounded-md"
                      >
                        Hủy
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={onSubmitDelete}
                        className="flex h-10 text-center justify-center items-center text-white shadow-lg w-full px-4 text-base font-semibold bg-red-600 rounded-md"
                      >
                        Xóa
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DeleteLocation;
