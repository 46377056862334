import * as ExcelJS from "exceljs";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IMealData, IMealReporterData } from "../../constants/meal-data";
import { sendEmail } from "../../stores/send-report";
import { IAppState } from "../../stores/state";
import { Button } from "../Button";
import { InputController } from "../Input";
import { Typography } from "../Typography";

type ExportExcelClientProps = {
  fileName?: string;
  csvData?: IMealData[];
  dataReportInfo?: IMealReporterData;
  isAdmin?: boolean;
};
interface CellAddress {
  /** Column number */
  c: number;
  /** Row number */
  r: number;
}
interface Range {
  /** Starting cell */
  s: CellAddress;
  /** Ending cell */
  e: CellAddress;
}

const ExportExcelClient: FC<ExportExcelClientProps> = ({
  fileName = "Danh sach bao cao",
  csvData = [],
  dataReportInfo,
  isAdmin = false,
}) => {
  const { isLoading } = useSelector((state: IAppState) => state.sendEmail);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheet.sheet";
  const fileExtension = ".xlsx";

  const [dataCSV, setDataCSV] = useState<string[][]>([]);
  // const [merges, setMerges] = useState<Array<Range>>([]);

  const mainTitle = [
    {
      header:
        "KHUNG CHỈ SỐ BÁO CÁO, GIÁM SÁT VÀ ĐÁNH GIÁ NỘI DUNG CSSK DD BM-TE",
      key: "mainTitle",
      width: 20,
    },
  ];

  const mainHeader = [
    "Mã Mục tiêu",
    "Nội dung",
    "Chỉ số đo lường",
    "Số lượng",
    "Đơn vị",
    "Tỉ lệ",
    "Đơn vị",
  ];

  const titleReporter = ["THÔNG TIN NGƯỜI ĐIỀN BÁO CÁO"];

  const headerReporter = [
    "Họ tên",
    "Email",
    "Chức vụ",
    "Khoa phòng",
    "Số điện thoại",
  ];

  useEffect(() => {
    const csvDataTemp: string[][] = [];

    csvDataTemp.push(mainHeader);

    if (csvData && csvData?.length > 0) {
      csvData.forEach((m) => {
        csvDataTemp.push([
          m?.name_value.indexOf("goal") >= 0
            ? "Mục tiêu chung"
            : `Hợp phần ${m?.name_value}. `,
          m.name,
          "",
          "",
        ]);
        m?.subs?.forEach((s, idx) => {
          if (s?.children && s?.children?.length > 0) {
            csvDataTemp.push([
              s?.sub_name_value?.indexOf("goal") >= 0
                ? `Mục tiêu cụ thể ${idx + 1}`
                : `Đầu ra ${s?.sub_name_value}. `,
              s?.sub_name,
              "",
              "",
            ]);
          }
          if (s?.labelInput) {
            csvDataTemp.push([
              s?.sub_name_value,
              "",
              s?.sub_name,
              s?.value + " ",
              s?.labelInput,
            ]);
          } else {
            if (s?.children && s?.children?.length > 0) {
              s.children.forEach((c) => {
                const valuePercent = c?.labelPercent ? c?.percent + "" : "";
                const unitPercent = c?.labelPercent ? c?.labelPercent : "";
                csvDataTemp.push([
                  c.child_name_value,
                  "",
                  c.child_name,
                  c?.labelInput || c?.labelPercent === " "
                    ? c?.value + ""
                    : c?.percent + "",
                  c?.labelInput || c?.labelPercent === " "
                    ? c?.labelInput
                    : "%",
                  c?.labelInput ? valuePercent : "",
                  c?.labelInput ? unitPercent : "",
                ]);
              });
            }
          }
        });
      });
    }

    if (dataReportInfo) {
      const reportValue = [
        dataReportInfo.reporter,
        dataReportInfo.email_reporter,
        dataReportInfo.role_reporter,
        dataReportInfo.department,
        dataReportInfo.phone_number_reporter,
      ];

      csvDataTemp.push([""], [""], titleReporter, headerReporter, reportValue);
    }

    setDataCSV(csvDataTemp);
    // setMerges(rootMerges);
  }, [csvData, dataReportInfo]);

  const styleXLSX = (sheet: ExcelJS.Worksheet) => {
    sheet.columns = mainTitle;

    sheet.addRows(dataCSV);

    sheet.mergeCells("A1:G1");
    sheet.mergeCells("A85:G85");

    sheet.views = [
      {
        state: "frozen",
        xSplit: 0, // No split in columns
        ySplit: 2, // Split after the second row
      },
    ];

    sheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
        };

        const cellValue = cell.value?.toString();

        if (
          cellValue &&
          (cellValue.includes("Mục tiêu cụ thể") ||
            cellValue.includes("Mục tiêu chung") ||
            cellValue.includes("Hợp phần") ||
            cellValue.includes("Đầu ra"))
        ) {
          cell.font = {
            bold: true,
            size: 12,
          };
        }
      });
    });

    sheet.getColumn(1).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "left",
    };

    sheet.getRows(2, 1)?.map((row) =>
      row.eachCell(
        (cell) =>
          (cell.alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "center",
          })
      )
    );

    sheet.getColumn(4).width = 13;
    sheet.getColumn(5).width = 13;
    sheet.getColumn(6).width = 13;
    sheet.getColumn(7).width = 13;

    sheet.getColumn(2).width = 65;
    sheet.getColumn(2).font = {
      bold: true,
    };

    sheet.getColumn(3).width = 65;

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "2e3192" },
    };
    sheet.getRow(1).font = {
      bold: true,
      size: 18,
      color: { argb: "FFFFFF" },
    };
    sheet.getRow(1).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center",
    };

    sheet.getRow(2).font = {
      bold: true,
      size: 12,
    };

    sheet.getRow(85).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "2e3192" },
    };
    sheet.getRow(85).font = {
      bold: true,
      size: 20,
      color: { argb: "FFFFFF" },
    };
    sheet.getRow(85).alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center",
    };

    sheet.getRow(86).font = {
      bold: true,
    };
    sheet.getRow(87).font = {
      bold: false,
    };
  };

  const exportToXLSX = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("data");

    styleXLSX(sheet);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: fileType,
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = fileName + fileExtension;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleSendEmail = (val: any) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("data");

    styleXLSX(sheet);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: fileType,
      });

      const formData = new FormData();
      formData.append("attach", blob, fileName + fileExtension);
      formData.append("email", val?.email);
      formData.append("message", fileName);
      formData.append("subject", "Lưu trữ báo cáo");

      dispatch(sendEmail(formData));
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <Button title="Tải báo cáo dạng Excel" onClick={() => exportToXLSX()} />
      {!isAdmin && (
        <>
          <Typography className="text-center">hoặc</Typography>
          <div>
            <Typography>Nhận báo cáo qua email để lưu trữ tốt hơn</Typography>
            <div className="flex gap-1">
              <InputController
                id="email-send-xlsx"
                error={errors?.email?.message}
                control={control}
                placeholder="Nhập vào email của bạn"
                name="email"
                rules={{
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Vui lòng nhập đúng định dạng email",
                  },
                  required: "Vui lòng nhập vào email",
                }}
              />
              <div className="w-40">
                <Button
                  id="submit-send-email-xlsx"
                  loading={isLoading}
                  title="Xác nhận"
                  onClick={handleSubmit(handleSendEmail)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExportExcelClient;
