import { Reducer } from "redux";
import { GlobalReducer } from "../app";
import { initialResponseState, IResponseState } from "../state";
import {
  HistoryReportsTypes,
  IGetHistoryReportsRequestAction,
  IGetReportByIDRequestAction,
} from "./history-reports.type";

export const GetHistoryReportsReducer: Reducer<
  IResponseState,
  IGetHistoryReportsRequestAction
> = (state = initialResponseState, action: IGetHistoryReportsRequestAction) =>
  GlobalReducer(
    state,
    action,
    HistoryReportsTypes.GET_HISTORY_REPORTS.toString()
  );

export const GetReportByIdReducer: Reducer<
  IResponseState,
  IGetReportByIDRequestAction
> = (state = initialResponseState, action: IGetReportByIDRequestAction) =>
  GlobalReducer(state, action, HistoryReportsTypes.GET_REPORT_BY_ID.toString());
