import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import { Requests } from "../../api/requests";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
} from "../app";
import { BASE_URL, ENDPOINTS } from "../../api/endpoint";
import { AxiosResponse } from "axios";
import { objectToFormData } from "../../helper";
import {
  IChangePasswordRequestAction,
  ICreateUserRequestAction,
  IDeleteUserRequestAction,
  ManageUserTypes,
} from "./manage-user.type";

function* createUser({ payload, type }: ICreateUserRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.manage_user;
    const res: AxiosResponse<string> = yield Requests.post(
      apiUrl,
      objectToFormData(payload)
    );
    if (res.data) {
      yield put(handleActionSucceeded(type, res));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchCreateUser() {
  yield takeLatest(ManageUserTypes.CREATE_USER, createUser);
}

function* deleteUser({ payload, type }: IDeleteUserRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.manage_user;
    const res: AxiosResponse<string> = yield Requests.post(
      apiUrl,
      objectToFormData(payload)
    );
    if (res.data) {
      yield put(handleActionSucceeded(type, res));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchDeleteUser() {
  yield takeLatest(ManageUserTypes.DELETE_USER, deleteUser);
}

function* changePassword({ payload, type }: IChangePasswordRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.manage_user;
    const res: AxiosResponse<string> = yield Requests.post(
      apiUrl,
      objectToFormData(payload)
    );
    if (res.data) {
      yield put(handleActionSucceeded(type, "Thay đổi mật khẩu thành công"));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchChangePassword() {
  yield takeLatest(ManageUserTypes.CHANGE_PASSWORD, changePassword);
}

export function* manageUserSaga() {
  yield all([
    fork(watchCreateUser),
    fork(watchDeleteUser),
    fork(watchChangePassword),
  ]);
}
