import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InputBase } from "../../../components/Input";
import { Typography } from "../../../components/Typography";
import { AreaTypes } from "../../../constants/meal-data";
import { ILocation } from "../../../stores/login-client";
import { IAppState } from "../../../stores/state";
import AddLocation from "./AddLocation";
import { TrashIcon } from "../../../svg-icons/TrashIcon";
import DeleteLocation from "./DeleteLocation";

type ListAreaProps = {
  type: AreaTypes;
  area: Array<ILocation>;
  onSelected?: (value: number) => void;
  selectedId?: number;
  loading?: boolean;
  parentId?: number;
};

const ListArea: FC<ListAreaProps> = ({
  type,
  area = [],
  onSelected = () => {},
  selectedId,
  loading = false,
  parentId,
}) => {
  const [query, setQuery] = useState("");
  const [createLocation, setCreateLocation] = useState<boolean>(false);

  const [locationId, setLocationId] = useState<string | undefined>(undefined);

  const resultCreateLocation = useSelector(
    (state: IAppState) => state.createLocations
  );

  const handleDeleteLocation = (id: string) => {
    setLocationId(id);
  };

  const filteredData =
    query === ""
      ? area
      : area.filter((i) =>
          i.province
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  useEffect(() => {
    if (resultCreateLocation.data) {
      setCreateLocation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultCreateLocation]);

  if (loading) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <Typography className="font-bold">
            {type === "province"
              ? "Tỉnh"
              : type === "district"
              ? "Huyện"
              : "Xã"}
          </Typography>
          <div className="m-0 cursor-pointer hover:bg-slate-100">
            <AddLocation />
          </div>
        </div>
        <div>
          <InputBase placeholder="Tìm kiếm" />
        </div>
        <div className="flex justify-center h-20 items-center">
          <div
            className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent"
            role="status"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography className="font-bold">
          {type === "province" ? "Tỉnh" : type === "district" ? "Huyện" : "Xã"}
        </Typography>
        {((type === "district" && parentId) ||
          (type === "ward" && parentId) ||
          type === "province") && (
          <div className="m-0 cursor-pointer hover:bg-slate-100">
            <AddLocation
              isShow={createLocation}
              onShow={() => setCreateLocation(true)}
              onCloseModal={() => setCreateLocation(false)}
              location_id={parentId}
              loading={resultCreateLocation.isLoading}
            />
          </div>
        )}
      </div>
      <div>
        <InputBase
          placeholder="Tìm kiếm"
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <ul className="gap-2 flex flex-col h-[50vh] overflow-auto">
        {filteredData.map((a, idx) => (
          <li
            key={idx}
            className={`bg-slate-100 px-3 py-2 relative flex items-center`}
          >
            {type === "province"
              ? a.province
              : type === "district"
              ? a.district
              : a.ward}
            <p
              onClick={() => handleDeleteLocation(a?.id?.toString())}
              className="absolute right-1 cursor-pointer opacity-60 hover:opacity-100 w-7 h-7 bg-red-100 flex justify-center items-center rounded-full"
            >
              <TrashIcon />
            </p>
          </li>
        ))}
      </ul>
      {locationId ? (
        <DeleteLocation
          location_id={locationId}
          isShow={!!locationId}
          onCloseModal={() => setLocationId(undefined)}
        />
      ) : null}
    </div>
  );
};

export default ListArea;
