import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { ButtonIcon } from "../components/ButtonIcon";
import { Logout } from "../components/Logout";
import { Sidebar } from "../components/Sidebar";
import Logo from "../assets/logo_byt.png";

export const Layout = () => {
  return (
    <div className="flex h-screen bg-white dark:bg-gray-900 w-full">
      <Sidebar />
      <div className="flex flex-col flex-1 w-full">
        <header className="z-10 py-4 bg-white shadow-md dark:bg-slate-800">
          <div className="flex items-center justify-end h-full px-6 w-full text-indigo-600 dark:textindigo-300">
            <ul className="flex items-center flex-shrink-0 space-x-6">
              {/* <li className="flex">
                <ToggleTheme />
              </li> */}
              <li className="flex flex-row">
                {/* <img className="w-32 h-28" src={Logo} alt="logo_byt" /> */}
                <div className="w-3" />
                <Logout role="admin" />
              </li>
            </ul>
          </div>
        </header>
        <main className="h-full py-10 px-8 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
