import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import { ILoginRequestAction, LoginTypes } from "./login.type";
import { Requests } from "../../api/requests";
import { AxiosResponse } from "axios";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
} from "../app";
import { BASE_URL, ENDPOINTS } from "../../api/endpoint";
import { IResponseLoginClient } from "../login-client";

function* login({ payload, type }: ILoginRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = BASE_URL.dev + ENDPOINTS.authenticate;
    const res: AxiosResponse<{ data: IResponseLoginClient }> =
      yield Requests.post(
        apiUrl,
        {
          ...payload,
        },
        false
      );
    if (res.data) {
      yield put(
        handleActionSucceeded<IResponseLoginClient>(type, res.data.data)
      );
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.data?.detail));
  }
}
function* watchLogin() {
  yield takeLatest(LoginTypes.LOGIN, login);
}

export function* rootLogin() {
  yield all([fork(watchLogin)]);
}
