import {
  IGetHistoryReports,
  IGetReportById,
} from "./history-reports.interface";

export enum HistoryReportsTypes {
  GET_HISTORY_REPORTS = "GET_HISTORY_REPORTS",
  GET_REPORT_BY_ID = "GET_REPORT_BY_ID",
}

export interface IGetHistoryReportsRequestAction {
  type: typeof HistoryReportsTypes.GET_HISTORY_REPORTS;
  payload?: IGetHistoryReports;
}

export interface IGetReportByIDRequestAction {
  type: typeof HistoryReportsTypes.GET_REPORT_BY_ID;
  payload?: IGetReportById;
}

export type HistoryReportsActions =
  | IGetHistoryReportsRequestAction
  | IGetReportByIDRequestAction;
