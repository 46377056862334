import { IGetProfileRequestAction, ProfileTypes } from "./profile.type";
import { all, fork } from "redux-saga/effects";
import { put, takeLatest } from "@redux-saga/core/effects";
import {
  handleActionFailed,
  handleActionRequested,
  handleActionSucceeded,
} from "../app";
import { IProfile } from "./profile.interface";
import { AxiosResponse } from "axios";
import { Requests } from "../../api/requests";

function* getProfile({ type }: IGetProfileRequestAction) {
  yield put(handleActionRequested(type));
  try {
    const apiUrl = "";
    const res: AxiosResponse<IProfile> = yield Requests.get(apiUrl);
    if (res.data) {
      yield put(handleActionSucceeded<IProfile>(type, res.data));
    } else {
      yield put(handleActionFailed(type, ""));
    }
  } catch (err: any) {
    yield put(handleActionFailed(type, err?.message));
  }
}
function* watchGetProfile() {
  yield takeLatest(ProfileTypes.GET_PROFILE, getProfile);
}

function* profileSaga() {
  yield all([fork(watchGetProfile)]);
}
export default profileSaga;
