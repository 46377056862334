import { useCallback } from "react";

type ButtonProps = {
  id?: string;
  title: string;
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  rounded?: boolean;
  className?: string;
};

export const Button: React.FC<ButtonProps> = ({
  id,
  title,
  style,
  startIcon,
  endIcon,
  loading = false,
  disabled = false,
  onClick = () => {},
  rounded = true,
  className,
}) => {
  const isDisabled = useCallback(() => {
    if (disabled || loading) return true;
    return false;
  }, [disabled, loading]);

  return (
    <button
      id={id || "button"}
      type="submit"
      style={style}
      onClick={onClick}
      disabled={disabled}
      className={`flex h-10 ${
        isDisabled() ? "pointer-events-none" : "pointer-events-auto"
      }  items-center justify-center w-full px-4 text-base font-semibold ${
        rounded ? "rounded-md" : ""
      } text-center text-white transition duration-200 ease-in ${
        isDisabled() ? "bg-gray-300" : "bg-primary"
      }  ${
        isDisabled() ? "" : "shadow-lg"
      } hover:bg-indigo-700 focus:outline-none ${className}`}
    >
      {loading ? (
        <div
          className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent"
          role="status"
        />
      ) : (
        <>
          {!!startIcon && startIcon}
          <span className="mx-1">{title}</span>
          {!!endIcon && endIcon}
        </>
      )}
    </button>
  );
};
