import axios, { AxiosRequestHeaders } from "axios";
import { KEY_TOKEN } from "../constants/app-const";

const defaultHeader = (token?: string | null, isFormData = false) => {
  const header: AxiosRequestHeaders = {
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    "Cache-Control": "no-cache",
  };
  if (token) header.Authorization = `Token ${token}`;
  return header;
};

class ApiRequests {
  public get<P>(apiUrl: string, params?: P, isAuth = true) {
    const headers = defaultHeader(
      isAuth ? localStorage.getItem(KEY_TOKEN) : null
    );
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl, { headers, params })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  public post<T>(apiUrl: string, data?: T, isAuth = true, isFormData = false) {
    const headers = defaultHeader(
      isAuth ? localStorage.getItem(KEY_TOKEN) : null,
      isFormData
    );
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl, data, { headers })
        .then((res) => resolve(res))
        .catch((err) => reject(err?.response));
    });
  }

  public put<T>(apiUrl: string, data?: T, isAuth = true) {
    const headers = defaultHeader(
      isAuth ? localStorage.getItem(KEY_TOKEN) : null
    );
    return new Promise((resolve, reject) => {
      axios
        .put(apiUrl, data, { headers })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  public delete<T>(apiUrl: string, data?: T, isAuth = true) {
    const headers = defaultHeader(
      isAuth ? localStorage.getItem(KEY_TOKEN) : null
    );
    return new Promise((resolve, reject) => {
      axios
        .delete(apiUrl, { headers, data })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
}

export const Requests = new ApiRequests();
