import { IAppMessageState } from "./app";
import {
  IResponseGetHistoryReports,
  IResponseGetReportById,
} from "./history-reports";
import { IListReports } from "./list-reports";
import { IResponseLocations } from "./location/location.interface";
import { IResponseLoginClient } from "./login-client";
import { IProfile } from "./profile";
import { IResponseSendReport } from "./send-report";

export interface IResponseState<T = any> {
  isLoading: boolean;
  data: T;
  message?: string;
}

export const initialResponseState: IResponseState<null> = {
  isLoading: false,
  data: null,
  message: "",
};

export interface IGlobalAction<T = any> extends Partial<IResponseState> {
  type: any;
  data?: T;
}

export type GlobalActions<T = any> = IGlobalAction<T>;

export interface IAppState {
  appMessage: IAppMessageState;
  login: IResponseState<IResponseLoginClient>;
  sendReport: IResponseState<IResponseSendReport>;
  sendEmail: IResponseState;
  loginClient: IResponseState<IResponseLoginClient>;
  //history-reports
  reportById: IResponseState<IResponseGetReportById[]>;
  getHistoryReports: IResponseState<IResponseGetHistoryReports>;
  profile: IResponseState<IProfile>;
  getListReports: IResponseState<IListReports>;
  locations: IResponseState<IResponseLocations>;
  createLocations: IResponseState<string>;
  deleteLocations: IResponseState;
  // manage users
  createUser: IResponseState<string>;
  deleteUser: IResponseState<string>;
  changePassword: IResponseState<string>;
}
