import { Reducer } from "redux";
import { GlobalReducer } from "../app";
import { initialResponseState, IResponseState } from "../state";
import { SendReportActions, SendReportTypes } from "./send-report.type";

export const SendReportReducer: Reducer<IResponseState, SendReportActions> = (
  state = initialResponseState,
  action: SendReportActions
) => GlobalReducer(state, action, SendReportTypes.SEND_REPORT.toString());

export const SendEmailReducer: Reducer<IResponseState, SendReportActions> = (
  state = initialResponseState,
  action: SendReportActions
) => GlobalReducer(state, action, SendReportTypes.SEND_EMAIL.toString());
