import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { KEY_INFO_USER } from "../../constants/app-const";

type PrivatePageProps = {
  canAccess?: boolean;
  element: React.ReactNode;
};

export const PrivatePageAdmin: FC<PrivatePageProps> = ({
  canAccess = false,
  element,
}) => {
  const infoUser: any = localStorage.getItem(KEY_INFO_USER);
  if (infoUser) {
    let inforParsed = JSON.parse(infoUser);
    if (inforParsed?.role === "admin") {
      return <>{element}</>;
    }
    if (inforParsed.role === "admin" && !canAccess) {
      return <Navigate to="/admin" />;
    }
  }
  localStorage.setItem(KEY_INFO_USER, "");
  return <Navigate to="/admin/login" />;
};

export const PrivatePageAgency: FC<PrivatePageProps> = ({ element }) => {
  const infoUser: any = localStorage.getItem(KEY_INFO_USER);
  if (infoUser) {
    let inforParsed = JSON.parse(infoUser);
    if (inforParsed?.role === "agency" || inforParsed?.role === "admin") {
      return <>{element}</>;
    }
  }
  localStorage.setItem(KEY_INFO_USER, "");
  return <Navigate to="/" />;
};
