import { fork, all } from "redux-saga/effects";
import historyReportsSaga from "./history-reports/history-reports.saga";
import { listReportSaga } from "./list-reports";
import { locationSaga } from "./location/location.saga";
import { rootLoginClientSaga } from "./login-client";
import { rootLogin } from "./login/login.saga";
import { manageUserSaga } from "./manage-user/manage-user.saga";
import profileSaga from "./profile/profile.saga";
import sendReportSaga from "./send-report/send-report.saga";

export function* rootSaga() {
  yield all([
    fork(rootLogin),
    fork(sendReportSaga),
    fork(rootLoginClientSaga),
    fork(historyReportsSaga),
    fork(profileSaga),
    fork(listReportSaga),
    fork(locationSaga),
    fork(manageUserSaga),
  ]);
}
