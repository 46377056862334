import { Reducer } from "redux";
import { GlobalReducer } from "../app";
import { initialResponseState, IResponseState } from "../state";
import { ListReportActions, ListReportTypes } from "./list-reports.type";

export const GetListReportReducer: Reducer<
  IResponseState,
  ListReportActions
> = (state = initialResponseState, action: ListReportActions) =>
  GlobalReducer(state, action, ListReportTypes.GET_LIST_REPORT.toString());
