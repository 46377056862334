import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { IMealData } from "../constants/meal-data";
import { ExportPdf } from "../pages/client/export-pdf";

type DownloadHistoryReportPdfProps = {
  sub_name: string;
  area: string;
  report: IMealData[];
};

export const downloadHistoryReportPdf = async ({
  sub_name,
  area,
  report = [],
}: DownloadHistoryReportPdfProps) => {
  const asPDF = pdf();
  asPDF.updateContainer(<ExportPdf report={report} area={area} />);
  const blob = await asPDF.toBlob();
  saveAs(blob, `${sub_name}.pdf`);
};
