import { IFormLoginClient } from "./login-client.interface";

export enum LoginClientTypes {
  LOGIN_CLIENT = "LOGIN_CLIENT",
}

export interface ILoginClientRequestAction {
  type: typeof LoginClientTypes.LOGIN_CLIENT;
  payload: IFormLoginClient;
}

export type LoginClientActions = ILoginClientRequestAction;
