import { MessageTypes } from "./app.inteface";

export enum AppTypes {
  APP_MESSAGE_SHOW = "APP_MESSAGE_SHOW",
  APP_MESSAGE_HIDE = "APP_MESSAGE_HIDE",
}

export interface IAppMessageShowAction {
  type: typeof AppTypes.APP_MESSAGE_SHOW;
  message: string;
  severity?: MessageTypes;
}
export interface IAppMessageHideAction {
  type: typeof AppTypes.APP_MESSAGE_HIDE;
}
