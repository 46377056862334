import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputBase } from "../../../components/Input";
import { Typography } from "../../../components/Typography";
import { getLocation } from "../../../stores/location/location.action";
import { ILocation } from "../../../stores/login-client";
import { IAppState } from "../../../stores/state";
import AddUser from "./AddUser";
import ChangePassword from "./ChangePassword";
import DeleteLocation from "./DeleteLocation";
import ListArea from "./ListArea";

const ManageArea = () => {
  const dispatch = useDispatch();

  const resultGetLocation = useSelector((state: IAppState) => state.locations);

  const [provinceSelected, setProvinceSelected] = useState<number | undefined>(
    undefined
  );
  const [districtSelected, setDistrictSelected] = useState<number | undefined>(
    undefined
  );
  const [wardSelected, setWardSelected] = useState<number | undefined>(
    undefined
  );

  const [listProvince, setListProvince] = useState<ILocation[]>([]);
  const [listDistrict, setListDistrict] = useState<ILocation[]>([]);
  const [listWard, setListWard] = useState<ILocation[]>([]);
  const [isCreateAcc, setIsCreateAcc] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [deleteLocation, setDeleteLocation] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getLocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectProvince = (id: number) => {
    if (provinceSelected === id) {
      return;
    }
    setProvinceSelected(id);
    setDistrictSelected(undefined);
    setListDistrict([]);
    setWardSelected(undefined);
    setListWard([]);
    dispatch(getLocation({ location_id: id }));
  };

  const handleSelectDistrict = (id: number) => {
    if (districtSelected === id) {
      return;
    }
    setDistrictSelected(id);
    setWardSelected(undefined);
    setListWard([]);
    dispatch(getLocation({ location_id: id }));
  };

  const handleSelectWard = (id: number) => {
    if (wardSelected === id) {
      return;
    }
    setWardSelected(id);
    dispatch(getLocation({ location_id: id }));
  };

  useEffect(() => {
    if (resultGetLocation?.data) {
      if (!provinceSelected) {
        if (Array.isArray(resultGetLocation.data?.location)) {
          setListProvince(resultGetLocation.data.location);
        }
      }
      if (provinceSelected && !districtSelected) {
        resultGetLocation.data["sub-locations"] &&
          setListDistrict(resultGetLocation.data["sub-locations"]);
      }
      if (provinceSelected && districtSelected && !wardSelected) {
        resultGetLocation.data["sub-locations"] &&
          setListWard(resultGetLocation.data["sub-locations"]);
      }
    }
  }, [resultGetLocation, provinceSelected, districtSelected, wardSelected]);

  return (
    <div className="w-full">
      <div className="w-full mb-6">
        <Typography variant="h2" bold>
          Danh sách thu thập thông tin
        </Typography>
      </div>
      <div className="w-full flex gap-4">
        <div className="w-1/4">
          <ListArea
            type="province"
            area={listProvince}
            onSelected={handleSelectProvince}
            selectedId={provinceSelected}
            loading={resultGetLocation.isLoading && !provinceSelected}
          />
        </div>
        {/* <div className="w-1/4">
          <ListArea
            type="district"
            area={listDistrict}
            onSelected={handleSelectDistrict}
            selectedId={districtSelected}
            loading={
              resultGetLocation.isLoading &&
              provinceSelected !== undefined &&
              districtSelected === undefined
            }
            parentId={provinceSelected}
          />
        </div>
        <div className="w-1/4">
          <ListArea
            type="ward"
            area={listWard}
            onSelected={handleSelectWard}
            selectedId={wardSelected}
            loading={
              resultGetLocation.isLoading &&
              districtSelected !== undefined &&
              wardSelected === undefined
            }
            parentId={districtSelected}
          />
        </div> */}
        {/* <div className="w-1/4">
          {resultGetLocation?.data?.location &&
            !Array.isArray(resultGetLocation?.data?.location) && (
              <>
                <p className="text-lg font-bold">
                  {resultGetLocation?.data?.location?.type === "P"
                    ? `${resultGetLocation?.data?.location?.province}`
                    : resultGetLocation?.data?.location?.type === "D"
                    ? `${resultGetLocation?.data?.location?.district}`
                    : `${resultGetLocation?.data?.location?.ward}`}
                </p>
                {resultGetLocation?.data?.userLocation?.length > 0 ? (
                  <>
                    <div className="my-4">
                      <p className="font-medium">Thông tin đăng nhập</p>
                      <p className="mt-3 text-sm">Mã đăng nhập</p>
                      <InputBase
                        disabled
                        value={
                          resultGetLocation?.data?.userLocation?.[0]?.username
                        }
                      />
                      <p className="mt-2 text-sm">Mật khẩu</p>
                      <InputBase disabled value="******" />
                      <ChangePassword
                        isShow={changePassword}
                        onShow={() => setChangePassword(true)}
                        onCloseModal={() => setChangePassword(false)}
                        username={
                          resultGetLocation?.data?.userLocation?.[0]?.username
                        }
                      />
                    </div>
                    <div>
                      <p className="font-medium">Xóa khu vực</p>
                      <p className="mt-1 text-sm italic">
                        Lưu ý: Nếu bạn xóa khu vực, các dữ liệu liên quan của
                        khu vực này cũng sẽ bị xóa hết.
                      </p>
                      <DeleteLocation
                        isShow={deleteLocation}
                        onShow={() => setDeleteLocation(true)}
                        onCloseModal={() => setDeleteLocation(false)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="my-4">
                      <p className="font-medium">Chưa có thông tin đăng nhập</p>
                      <p className="mt-2 text-sm italic">
                        Hãy tạo tài khoản cho khu vực này
                      </p>
                      {!Array.isArray(resultGetLocation?.data?.location) &&
                        resultGetLocation?.data?.location?.id && (
                          <AddUser
                            location_id={resultGetLocation?.data?.location?.id}
                            isShow={isCreateAcc}
                            onCloseModal={() => setIsCreateAcc(false)}
                            onShow={() => setIsCreateAcc(true)}
                          />
                        )}
                    </div>
                  </>
                )}
              </>
            )}
        </div> */}
      </div>
    </div>
  );
};

export default ManageArea;
