import { Reducer } from "redux";
import { GlobalReducer } from "../app";
import { initialResponseState, IResponseState } from "../state";
import {
  IChangePasswordRequestAction,
  ICreateUserRequestAction,
  IDeleteUserRequestAction,
  ManageUserTypes,
} from "./manage-user.type";

export const CreateUserReducer: Reducer<
  IResponseState,
  ICreateUserRequestAction
> = (state = initialResponseState, action: ICreateUserRequestAction) =>
  GlobalReducer(state, action, ManageUserTypes.CREATE_USER.toString());

export const DeleteUserReducer: Reducer<
  IResponseState,
  IDeleteUserRequestAction
> = (state = initialResponseState, action: IDeleteUserRequestAction) =>
  GlobalReducer(state, action, ManageUserTypes.DELETE_USER.toString());

export const ChangePasswordReducer: Reducer<
  IResponseState,
  IChangePasswordRequestAction
> = (state = initialResponseState, action: IChangePasswordRequestAction) =>
  GlobalReducer(state, action, ManageUserTypes.CHANGE_PASSWORD.toString());
