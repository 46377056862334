import { IFormLoginClient } from "./login-client.interface";
import {
  ILoginClientRequestAction,
  LoginClientTypes,
} from "./login-client.type";

export const loginClient = (
  payload: IFormLoginClient
): ILoginClientRequestAction => ({
  type: LoginClientTypes.LOGIN_CLIENT,
  payload,
});
