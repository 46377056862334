import { useEffect, useState } from "react";

export * from "./downloadPDF";

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export function isNumeric(value: any) {
  return /^-{0,1}\d+$/.test(value);
}

export function buildFormData(formData: any, data: any, parentKey?: string) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    if (Array.isArray(data)) {
      formData.append(parentKey, data);
    } else {
      Object.keys(data).forEach((key) => {
        const fieldKey = isNumeric(key) ? "[]" : `[${key}]`;
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}${fieldKey}` : key
        );
      });
    }
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function objectToFormData(data: any) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const objectToQuery = (obj: any) => {
  const qs = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");

  return qs;
};

export const removeDotInNumber = (num: string) => {
  if (num.indexOf(".")) return num.replace(".", "");
  return num;
};
export const convertPercent = (num: string) => {
  if (num.indexOf(".")) return num.replace(".", ",");
  return num;
};

// format money
const addCommasMoney = (num: any) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
const addCommasPhone = (num: any) =>
  num.toString().replace(/\B(?=(\d{2})+(?!\d))/g, " ");
const addCommasNumber = (num: any) =>
  num.toString().replace(/\B(?=(\d{2})+(?!\d))/g, "");
const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");

export const formatCustomInput = (num: any, type = "money") => {
  if (num) {
    if (type === "number") return addCommasNumber(removeNonNumeric(num));
    if (type === "phone") return addCommasPhone(removeNonNumeric(num));
    if (type === "money") return addCommasMoney(removeNonNumeric(num));
    return addCommasMoney(removeNonNumeric(num));
  }
  return "";
};
