import { DocumentIcon, HomeIcon } from "../../svg-icons";
import { SingleLink } from "./SingleLink";
import Logo from "../../assets/logo_byt.png";

export const Sidebar = () => {
  return (
    <aside className="z-20 flex-shrink-0 hidden w-64 overflow-y-auto shadow-md bg-white dark:bg-slate-800 md:block">
      <div className="py-4 text-gray-500 dark:text-gray-400">
        <div className="flex flex-col items-center gap-3">
          <img className="w-20 h-20" src={Logo} alt="logo_byt" />
          <p className="ml-4 text-lg font-bold text-gray-800 dark:text-gray-200 pr-2 text-center">
            CHƯƠNG TRÌNH MỤC TIÊU QUỐC GIA DÂN TỘC THIỂU SỐ VÀ MIỀN NÚI
          </p>
        </div>
        <ul className="mt-6">
          <SingleLink
            name="Thống kê báo cáo"
            to="/admin"
            canAccess
            icon={<HomeIcon />}
          />
          <SingleLink
            name="Quản lý khu vực"
            to="/admin/manage-area"
            canAccess
            icon={<DocumentIcon />}
          />
        </ul>
      </div>
    </aside>
  );
};
