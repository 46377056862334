import {
  Controller,
  Control,
  FieldValues,
  RegisterOptions,
  FieldPath,
} from "react-hook-form";

export type InputBaseProps = {
  placeholder?: string;
  icon?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  defaultValue?: string | number | readonly string[];
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[];
  error?: any;
  textCenter?: boolean;
  disabled?: boolean;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  id?: string;
  onFocus?: () => void;
};
type InputControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<InputBaseProps, "onChange" | "value"> & {
  control: Control<any, any>;
  key?: React.Key;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
};

export const InputBase: React.FC<InputBaseProps> = ({
  placeholder,
  icon,
  type = "text",
  defaultValue,
  name,
  onChange,
  value,
  error,
  textCenter = false,
  disabled = false,
  className = "py-2 px-4",
  id,
  onFocus = () => null,
}) => {
  return (
    <div className="w-full">
      <div
        className={`flex relative rounded-md overflow-hidden ${
          error ? "border-red-600 border" : ""
        }`}
      >
        {icon && (
          <span className="inline-flex items-center pl-4 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-100 text-gray-500 text-sm">
            {icon}
          </span>
        )}
        <input
          id={id}
          type={type}
          className={`flex-1 appearance-none w-full ${className} bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-100 text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-0 focus:border-transparent`}
          placeholder={placeholder}
          defaultValue={defaultValue}
          data-test-id={`input-${id}`}
          name={name}
          onFocus={(e) => {
            e.target.placeholder = "";
            onFocus();
          }}
          disabled={disabled}
          onChange={onChange}
          onBlur={(e) => (e.target.placeholder = placeholder || "")}
          value={value}
          style={{
            textAlign: textCenter ? "center" : "left",
          }}
        />
      </div>
      {error && <span className="text-xs text-red-600">{error}</span>}
    </div>
  );
};

export const InputController: React.FC<InputControllerProps> = ({
  id,
  placeholder,
  icon,
  type = "text",
  defaultValue = "",
  name = "",
  control,
  key,
  rules,
  error,
  textCenter = false,
  disabled = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <InputBase
          id={id || "input"}
          placeholder={placeholder}
          icon={icon}
          type={type}
          onChange={onChange}
          value={value}
          error={error}
          textCenter={textCenter}
          disabled={disabled}
        />
      )}
      defaultValue={defaultValue}
      key={key}
      rules={rules}
    />
  );
};
