import { Disclosure } from "@headlessui/react";
import { isArray } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../..";
import ExportExcelClient from "../../../components/ExportExcelClient";
import { getLabelOption } from "../../../components/Select";
import { Typography } from "../../../components/Typography";
import { formatCustomInput } from "../../../helper";
import { getReportById } from "../../../stores/history-reports";
import { IAppState } from "../../../stores/state";
import { ArrowDownIcon } from "../../../svg-icons/ArrowDownIcon";
import { OptionSelect } from "../../client/send-report/OptionSelect";

const queryString = require("query-string");

const ReportById = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const navigate = useNavigate();

  const listReports: any = useContext(AppContext);

  const [optionValue, setOptionValue] = useState(
    listReports.length > 0 && listReports[listReports.length - 1].id
  );

  const currentSearch = queryString.parse(search);

  const { report_id } = currentSearch;

  const resultGet = useSelector((state: IAppState) => state.reportById);

  const dataParsed = resultGet?.data?.[0]?.data
    ? resultGet?.data?.[0]?.data
    : "";
  const dataReportInfo = resultGet?.data?.[0]?.report_info
    ? JSON.parse(resultGet?.data?.[0]?.report_info)
    : "";
  const dataKeyCheck = resultGet?.data?.[0]?.key_check
    ? resultGet?.data?.[0]?.key_check
    : "";
  const dataUnit = resultGet?.data?.[0]?.unit ? resultGet?.data?.[0]?.unit : "";

  const clearListReports = () => {
    listReports.splice(0, listReports.length);
  };

  useEffect(() => {
    if (listReports.length < 1) {
      navigate("/admin");
    }
  }, [listReports]);

  useEffect(() => {
    if (typeof report_id === "string") {
      if (!report_id || report_id == "undefined") return;

      dispatch(getReportById({ report_id: report_id }));
    } else {
      if (
        !report_id[report_id.length - 1] ||
        report_id[report_id.length - 1] == "undefined"
      )
        return;

      dispatch(getReportById({ report_id: report_id[report_id.length - 1] }));
    }
  }, [report_id[report_id.length - 1]]);

  if (resultGet.isLoading) {
    return (
      <span className="flex justify-center items-center w-full h-fulL">
        <div
          className="spinner-border animate-spin inline-block w-6 h-6 border-4 border-x-primary border-b-primary rounded-full border-t-transparent"
          role="status"
        />
      </span>
    );
  }

  if (!report_id[0] || report_id[0] == "undefined" || !dataParsed)
    return (
      <div className="w-full flex flex-col">
        <Link
          to="/admin"
          className="flex justify-start items-center cursor-pointer"
          onClick={clearListReports}
        >
          <p className="rotate-90 transform">
            <ArrowDownIcon width={22} height={22} />
          </p>
          <p className="underline font-semibold w-12">Trở lại</p>
        </Link>
        <Typography variant="h1">
          Không có dữ liệu, hay quay trở lại sau
        </Typography>
      </div>
    );

  return (
    <div className="w-full flex flex-col">
      <div className="flex items-start">
        <Link
          to="/admin"
          className="flex justify-start items-center cursor-pointer"
          onClick={clearListReports}
        >
          <p className="rotate-90 transform">
            <ArrowDownIcon width={22} height={22} />
          </p>
          <p className="underline font-semibold w-12">Trở lại</p>
        </Link>
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start flex-col ml-4">
            <Typography variant="h1">
              Báo cáo chỉ số chương trình mục tiêu quốc gia dân tộc thiểu số
            </Typography>
            <Typography variant="h3">
              {`${getLabelOption(OptionSelect, dataKeyCheck)} - ${dataUnit}`}
            </Typography>
          </div>

          <div className="flex justify-start flex-col ml-4">
            <Typography variant="h3">
              Số lượng báo cáo: {listReports.length}
            </Typography>
            <select
              className="bg-slate-100 hover:bg-slate-500/10 cursor-pointer p-2 rounded-xl"
              defaultValue={optionValue}
              onChange={(e) => {
                const report_id = e.target.value;
                setOptionValue(report_id);
                dispatch(getReportById({ report_id }));
              }}
            >
              {listReports.map((report: any) => (
                <option key={report.id} value={report.id}>
                  {report.created_at}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="my-5 flex justify-end w-full">
        <div>
          {dataParsed && JSON.parse(dataParsed)?.length > 0 && (
            <ExportExcelClient
              isAdmin
              fileName={`${getLabelOption(
                OptionSelect,
                dataKeyCheck
              )} - ${dataUnit}`}
              csvData={JSON.parse(dataParsed)}
              dataReportInfo={dataReportInfo}
            />
          )}
        </div>
      </div>
      {dataParsed &&
        Array.isArray(JSON.parse(dataParsed)) &&
        JSON.parse(dataParsed)?.length > 0 &&
        JSON.parse(dataParsed).map((meal: any, index: number) => (
          <div
            key={index}
            className="w-full border mx-4 border-indigo-100 bg-slate-50 overflow-hidden"
          >
            <div className="mb-2">
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <div className="flex items-center justify-between mb-3 w-full bg-primary px-2 py-1">
                      <Typography
                        variant="h3"
                        className="text-left text-white"
                        bold
                      >
                        {meal.name_value?.indexOf("goal") >= 0
                          ? `Mục tiêu chung: ${meal.name}`
                          : `Hợp phần ${meal?.name_value}. ${meal.name}`}
                      </Typography>
                    </div>
                    <Disclosure.Panel>
                      {meal.subs.map((sub: any, index_sub: number) => {
                        if (sub?.labelInput) {
                          return (
                            <div key={index_sub} className="mb-3 flex">
                              <div className="px-2 ml-8 py-1 flex">
                                <div className="flex">
                                  <Typography bold className="mr-1 w-max">
                                    Đầu ra {index + 1}.{index_sub + 1}.{" "}
                                  </Typography>
                                </div>
                                <Typography>{sub.sub_name}</Typography>
                              </div>
                              <div className="w-2/5 flex flex-col gap-1 xl:grid xl:grid-cols-2">
                                <div className="flex items-center">
                                  <div className="block mr-2">{sub?.value}</div>
                                  <Typography>{sub.labelInput}</Typography>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return (
                          <div key={index_sub} className="mb-3">
                            <div className="bg-slate-100 px-2 ml-8 py-1 flex">
                              <div className="flex">
                                <Typography bold className="mr-1 w-max">
                                  {sub?.sub_name_value.indexOf("goal") === -1
                                    ? `Đầu ra ${sub?.sub_name_value}. `
                                    : `Mục tiêu cụ thể ${index_sub + 1}. `}
                                </Typography>
                              </div>
                              <Typography bold>{sub.sub_name}</Typography>
                            </div>
                            <div className="pl-8">
                              <table className="table-auto w-full">
                                <tbody>
                                  {isArray(sub.children) &&
                                    sub.children !== undefined &&
                                    sub.children.map(
                                      (child: any, index_child: number) => {
                                        const length =
                                          sub.children?.length || 1;
                                        return (
                                          <tr
                                            key={index_child}
                                            className={
                                              index_child < length - 1
                                                ? "border-b border-b-slate-200"
                                                : ""
                                            }
                                          >
                                            <td className="px-2 py-1 flex items-center">
                                              <div className="flex flex-1 pr-2">
                                                <Typography
                                                  bold
                                                  className="mr-1"
                                                >
                                                  {child?.child_name_value}.{" "}
                                                </Typography>
                                                <Typography>
                                                  {child.child_name}
                                                </Typography>
                                              </div>
                                              <div className="w-2/5 flex flex-col gap-1 xl:grid xl:grid-cols-2">
                                                {(child?.labelInput ||
                                                  child?.labelInput === " " ||
                                                  child?.labelPercent ===
                                                    " ") && (
                                                  <div className="flex items-center">
                                                    <div className="block mr-2">
                                                      {child?.value && (
                                                        <>
                                                          {child?.value === "-"
                                                            ? "-"
                                                            : formatCustomInput(
                                                                child?.value
                                                              )}
                                                        </>
                                                      )}
                                                    </div>
                                                    <Typography>
                                                      {child.labelInput}
                                                    </Typography>
                                                  </div>
                                                )}
                                                {child?.labelPercent && (
                                                  <div className="flex items-center">
                                                    {child?.labelInput && (
                                                      <Typography>
                                                        Tỷ lệ
                                                      </Typography>
                                                    )}
                                                    <div className="block mr-2 ml-1">
                                                      {child?.percent && (
                                                        <>
                                                          {child?.percent ===
                                                          "-"
                                                            ? "-"
                                                            : child?.percent}
                                                        </>
                                                      )}
                                                    </div>
                                                    <Typography>
                                                      {child.labelPercent}
                                                    </Typography>
                                                  </div>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        );
                      })}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        ))}
      <div className="xl:w-3/5 lg:w-4/5 md:w-4/5 sm:w-5/6 border mx-auto mt-4 px-4 py-4 border-indigo-100 bg-slate-50 overflow-hidden">
        <Typography bold className="text-lg text-center">
          Thông tin người điền báo cáo
        </Typography>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Typography className="text-sm">Họ tên đầy đủ:</Typography>
            <Typography className="text-sm">
              {dataReportInfo?.reporter}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm">Chức vụ:</Typography>
            <Typography className="text-sm">
              {dataReportInfo?.role_reporter}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm">Khoa phòng:</Typography>
            <Typography className="text-sm">
              {dataReportInfo?.department}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm">Số điện thoại:</Typography>
            <Typography className="text-sm">
              {dataReportInfo?.phone_number_reporter}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm">Email:</Typography>
            <Typography className="text-sm">
              {dataReportInfo?.email_reporter}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportById;
