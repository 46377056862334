import { memo, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronIcon } from "../../svg-icons";
import { Typography } from "../Typography";
import { useLocation, useNavigate } from "react-router-dom";
const queryString = require("query-string");

type PlacementTypes = "left" | "right";
interface IOptions {
  value: any;
  label: string;
}
type SelectProps = {
  placement?: PlacementTypes;
  label?: string;
  options: Array<IOptions>;
  value?: any;
  onChange?: (value: any) => void;
  error?: any;
  classNameContainer?: string;
  className?: string;
};

export const getLabelOption = (options: IOptions[], value: string) => {
  return options?.find((o) => o?.value === value)?.label;
};

export const SelectBase: React.FC<SelectProps> = memo(
  ({
    placement = "left",
    label = "Tất cả",
    options,
    value = null,
    onChange = () => {},
    error,
    classNameContainer = "",
    className = "",
  }) => {
    const renderLabel = (value: string) => {
      return options.find((o) => o.value === value)?.label;
    };
    return (
      <Listbox value={value} onChange={onChange}>
        <div className={`relative inline-block ${classNameContainer}`}>
          <Listbox.Button
            className={`relative z-10 flex items-center border ${
              error ? "border-red-600" : "border-white"
            } font-semibold p-2 text-sm text-gray-800 dark:text-gray-200 bg-white rounded ${className}`}
          >
            <span className="mx-1 text-base">
              {value ? renderLabel(value) : label}
            </span>
            <ChevronIcon />
          </Listbox.Button>
          {error && <span className="text-xs text-red-600">{error}</span>}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options
              className={`absolute ${placement}-0 z-50 py-2 px-4 w-auto max-h-[300px] overflow-y-auto mt-2 bg-white dark:bg-slate-800 border border-gray-100 dark:border-gray-700 shadow-lg origin-top-right rounded-md`}
            >
              {options.map((option, index) => (
                <Listbox.Option key={index} value={option.value}>
                  <Typography
                    role="menuitem"
                    className="flex justify-between w-full items-center px-2 py-2 dark:hover:bg-slate-700 hover:bg-gray-100 cursor-pointer min-w-max"
                  >
                    <span className="min-w-[176px]">{option.label}</span>
                  </Typography>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    );
  }
);

export const SelectChangeUrl: React.FC<SelectProps> = memo(
  ({ placement = "left", label = "Tất cả", options }) => {
    const { search, pathname } = useLocation();
    const currentSearch = queryString.parse(search);
    const { q } = currentSearch;
    const navigate = useNavigate();

    const handleChange = (value: any) => {
      currentSearch["q"] = value;
      const queries = queryString.stringify(currentSearch);
      navigate(`${pathname}?${queries}`);
    };

    const renderLabel = (value: string) => {
      return options.find((o) => o.value === value)?.label;
    };

    return (
      <Listbox value={q} onChange={handleChange}>
        <div className="relative inline-block w-full">
          <Listbox.Button className="relative w-full justify-between z-10 flex items-center p-2 text-sm text-gray-800 dark:text-gray-200 bg-gray-100 rounded-md focus:border-none focus:outline-none focus:ring-0 dark:bg-gray-800">
            <span className="mx-1 text-base w-max">
              {q ? renderLabel(q) : label}
            </span>
            <ChevronIcon />
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options
              className={`absolute ${placement}-0 z-50 py-2 w-auto mt-2 bg-white dark:bg-slate-800 border border-gray-100 dark:border-gray-700 shadow-lg origin-top-right rounded-md`}
            >
              {options.map((option, index) => (
                <Listbox.Option key={index} value={option.value}>
                  <Typography
                    role="menuitem"
                    className="flex justify-between w-full items-center px-2 py-2 dark:hover:bg-slate-700 hover:bg-gray-100 cursor-pointer min-w-max"
                  >
                    <span className="ml-2 min-w-44">{option.label}</span>
                  </Typography>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    );
  }
);
