import { Reducer } from "redux";
import { GlobalReducer } from "../app";
import { initialResponseState, IResponseState } from "../state";
import {
  IGetProfileRequestAction,
  ProfileActions,
  ProfileTypes,
} from "./profile.type";

export const GetProfileReducer: Reducer<IResponseState, ProfileActions> = (
  state = initialResponseState,
  action: IGetProfileRequestAction
) => GlobalReducer(state, action, ProfileTypes.GET_PROFILE.toString());
