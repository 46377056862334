import { ISendReport } from "./send-report.interface";
import {
  ISendEmailRequestAction,
  ISendReportRequestAction,
  SendReportTypes,
} from "./send-report.type";

export const sendReport = (payload: ISendReport): ISendReportRequestAction => ({
  type: SendReportTypes.SEND_REPORT,
  payload,
});
export const sendEmail = (payload: FormData): ISendEmailRequestAction => ({
  type: SendReportTypes.SEND_EMAIL,
  payload,
});
