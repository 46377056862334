import { ICreateLocation, IFilterLocation } from "./location.interface";

export enum LocationTypes {
  GET_LOCATION = "GET_LOCATION",
  CREATE_LOCATION = "CREATE_LOCATION",
  DELETE_LOCATION = "DELETE_LOCATION",
}

export interface IGetLocationRequestAction {
  type: typeof LocationTypes.GET_LOCATION;
  payload?: IFilterLocation;
}
export interface ICreateLocationRequestAction {
  type: typeof LocationTypes.CREATE_LOCATION;
  payload: ICreateLocation;
}

export interface IDeleteLocationRequestAction {
  type: typeof LocationTypes.DELETE_LOCATION;
  id: string;
}

export type LocationActions =
  | IGetLocationRequestAction
  | ICreateLocationRequestAction
  | IDeleteLocationRequestAction;
