import { Document, Page, Text, View, Font } from "@react-pdf/renderer";
import React, { FC } from "react";
import { styles } from "./style";
import MontserratBold from "../../../assets/fonts/Montserrat-Bold.ttf";
import MontserratMedium from "../../../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../../assets/fonts/Montserrat_SemiBold.ttf";
import MontserratRegular from "../../../assets/fonts/Montserrat-Regular.ttf";
import { IMealData } from "../../../constants/meal-data";
import { formatCustomInput } from "../../../helper";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
    {
      src: MontserratSemiBold,
      fontWeight: 500,
    },
    {
      src: MontserratRegular,
      fontWeight: 300,
    },
  ],
});

type ExportPdfProps = {
  area: string;
  report: IMealData[];
};

export const ExportPdf: FC<ExportPdfProps> = ({ area, report = [] }) => {
  return (
    <Document>
      <Page size="A4" style={styles.rootPage}>
        <View style={styles.header}>
          <Text style={styles.titleHeader}>
            BÁO CÁO CHỈ SỐ CHƯƠNG TRÌNH MỤC TIÊU QUỐC GIA DÂN TỘC THIỂU SỐ
          </Text>
          <Text
            style={{
              color: "#2e2e2e",
              paddingLeft: 40,
              paddingRight: 40,
              fontSize: 12,
              fontWeight: 500,
              fontFamily: "Montserrat",
              marginTop: 10,
              textAlign: "center",
            }}
          >
            {area}
          </Text>
        </View>
        <View style={styles.body}>
          {report.map((meal, index) => (
            <View style={styles.subContent} key={index}>
              <Text style={styles.titleSubContent}>
                Hợp phần {index + 1}. {meal.name}
              </Text>
              {meal.subs.map((sub, index_sub) => (
                <View style={styles.boxSubContent} key={index_sub}>
                  <View style={styles.boxTitleSubContent}>
                    <Text style={styles.titleBoxSubContent}>
                      {index + 1}.{index_sub + 1}. {sub.sub_name}
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={styles.table}>
                    {/* {sub.children.map((child, index_child) => (
                      <View style={styles.bodyTable} key={index_child}>
                        <View
                          style={{
                            width: "10%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={styles.inputTable1}>
                            {child.labelInput === "%"
                              ? child.value
                              : formatCustomInput(Number(child.value), "money")}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "90%",
                            display: "flex",
                            justifyContent: "center",
                            paddingLeft: 4,
                            paddingRight: 4,
                          }}
                        >
                          <Text style={styles.contentItemInfo}>
                            {child.child_name}
                          </Text>
                        </View>
                      </View>
                    ))} */}
                  </View>
                </View>
              ))}
            </View>
          ))}
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.containerPageNumber}>
            <Text
              render={({ pageNumber, totalPages }) => (
                <Text style={styles.textFooter}>
                  Trang {pageNumber}/
                  <Text style={styles.textFooter}>{totalPages}</Text>
                </Text>
              )}
            ></Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
