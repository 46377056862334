import { combineReducers } from "redux";
import { IAppState } from "./state";
import { AppMessageReducer } from "./app/app.reducer";
import {
  SendEmailReducer,
  SendReportReducer,
} from "./send-report/send-report.reducer";
import { LoginReducer } from "./login";
import { LoginClientReducer } from "./login-client";
import {
  GetHistoryReportsReducer,
  GetReportByIdReducer,
} from "./history-reports";
import { GetProfileReducer } from "./profile";
import { GetListReportReducer } from "./list-reports";
import {
  CreateLocationReducer,
  DeleteLocationReducer,
  GetLocationReducer,
} from "./location/location.reducer";
import {
  ChangePasswordReducer,
  CreateUserReducer,
  DeleteUserReducer,
} from "./manage-user/manage-user.reducer";

// Combine reducers
export const rootReducer = combineReducers<IAppState>({
  appMessage: AppMessageReducer,
  login: LoginReducer,
  // send-report reducers
  sendReport: SendReportReducer,
  sendEmail: SendEmailReducer,
  //login-client
  loginClient: LoginClientReducer,
  //history-reports
  getHistoryReports: GetHistoryReportsReducer,
  getListReports: GetListReportReducer,
  reportById: GetReportByIdReducer,
  // profile
  profile: GetProfileReducer,
  // locations
  locations: GetLocationReducer,
  createLocations: CreateLocationReducer,
  deleteLocations: DeleteLocationReducer,
  // manage users
  createUser: CreateUserReducer,
  deleteUser: DeleteUserReducer,
  changePassword: ChangePasswordReducer,
});
