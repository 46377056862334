import React from "react";
import { RouteObject } from "react-router-dom";
import { PrivatePageAdmin, PrivatePageAgency } from "./components/PrivatePage";
import { Layout } from "./layout";
import ManageArea from "./pages/admin/manage-area";
import ReportById from "./pages/admin/report-by-id";
import LoginClient from "./pages/client/login-client";

const Dashboard = React.lazy(() => import("./pages/admin/dashboard"));
const SendReport = React.lazy(() => import("./pages/client/send-report"));
const Login = React.lazy(() => import("./pages/admin/login"));
const HistoryReports = React.lazy(
  () => import("./pages/client/history-reports")
);
const DetailReport = React.lazy(() => import("./pages/admin/detail-report"));

export const RenderProtectedRoutes = () => {
  const protectedRoutes: RouteObject[] = [
    {
      path: "admin",
      element: <PrivatePageAdmin element={<Layout />} canAccess />,
      children: [
        {
          index: true,
          element: <PrivatePageAdmin element={<Dashboard />} canAccess />,
        },
        {
          path: "manage-area",
          element: <PrivatePageAdmin element={<ManageArea />} canAccess />,
        },
        {
          path: "detail-report",
          element: <PrivatePageAdmin element={<DetailReport />} canAccess />,
        },
        {
          path: "report-by-id",
          element: <PrivatePageAdmin element={<ReportById />} canAccess />,
        },
      ],
    },
    {
      path: "send-report",
      element: <SendReport />,
    },
    {
      path: "history-reports",
      element: <PrivatePageAgency element={<HistoryReports />} canAccess />,
    },
    {
      path: "admin/login",
      element: <Login />,
    },
    {
      index: true,
      element: <LoginClient />,
    },
  ];

  return protectedRoutes;
};
