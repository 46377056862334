import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  rootPage: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  header: {
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
  },
  titleHeader: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#000",
    width: "100%",
    textAlign: "center",
    fontFamily: "Montserrat",
    marginBottom: 10,
    marginTop: 15,
  },
  body: {
    display: "flex",
    width: "100%",
    paddingTop: 30,
    flexDirection: "column",
  },
  subContent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingBottom: 40,
  },
  titleSubContent: {
    backgroundColor: "#2E3192",
    paddingTop: 8,
    paddingBottom: 8,
    color: "#fff",
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  textFooter: {
    fontFamily: "Montserrat",
    fontSize: 8,
    color: "#2e2e2e",
  },
  boxSubContent: {
    paddingLeft: 40,
    display: "flex",
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: "column",
    paddingRight: 40,
  },
  boxTitleSubContent: {
    display: "flex",
    flexDirection: "column",
  },
  titleBoxSubContent: {
    color: "#2e2e2e",
    fontSize: 11,
    fontWeight: 500,
    fontFamily: "Montserrat",
    backgroundColor: "#F5F5FA",
    paddingVertical: 5,
    paddingHorizontal: 8,
  },
  contentItemInfo: {
    fontSize: 11,
    color: "#2e2e2e",
    fontFamily: "Montserrat",
    fontWeight: 300,
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  headerTable: {
    display: "flex",
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#F3F2F7",
    paddingLeft: 15,
    flexDirection: "row",
  },
  titleHeaderTable: {
    fontSize: 9,
    fontWeight: 500,
    color: "#2e2e2e",
    fontFamily: "Montserrat",
  },
  bodyTable: {
    display: "flex",
    flexDirection: "row",
    padding: "6px 0px 6px 10px",
    borderBottom: "1px solid #E9ECEF",
  },
  inputTable1: {
    color: "#2e2e2e",
    backgroundColor: "#F8F8F8",
    width: "55px",
    height: "19px",
    borderRadius: 3,
    marginRight: 5,
    fontSize: 9,
    textAlign: "center",
    paddingTop: 4,
    fontFamily: "Montserrat",
  },
  inputTableTotal1: {
    color: "#2e2e2e",
    backgroundColor: "#FFCC4D33",
    width: "55px",
    height: "19px",
    borderRadius: 3,
    marginRight: 5,
    fontSize: 11,
    marginLeft: 10,
    textAlign: "center",
    paddingTop: 3,
    fontFamily: "Montserrat",
  },
  footer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 10,
  },
  containerPageNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textPage: {
    fontFamily: "Montserrat",
    fontSize: 8,
    color: "#6E6B7B",
  },
});
